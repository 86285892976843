import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle} from "../../../components";
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import Breed from '../../Breed/components/breed';
import AddIcon from '@material-ui/icons/Add';
import Switch from "@material-ui/core/Switch"
import { MaterialTable } from '../../../components'

let schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    purchaseDate: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    breedIndex: {
        presence: { allowEmpty: false, message: 'is required' },
    }
};

const tranferSchema ={
    ...schema,
    movementDate: {
        presence: { allowEmpty: false, message: 'is required' },
    },
}

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    addIconCss:{
        marginLeft:theme.spacing(2),
    },
    typeLabel:{
        borderRadius:"6px",
        padding:"3px",
        fontWeight:"700",
        marginTop:"5px",
        textAlign:"center"
    },
    typeLabelActive:{
        borderRadius:"13px",
        padding:"0px 7px",
        fontWeight:"700",
        textAlign:"center"
    },
}));
const GroupBatch = ({ deleteErrorMessage,setGroupErrorMessage,addBatchError, errorMessage, onEdit, data, breeds, sheds, onSave, handleClose, showDialog, editMode,closeBreedDialog,showBreedDialog,onBreedSave,addBreedDialog,onGroupBatchTransfer,onGroupBatchCost}) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [transferBatch,setTransferBatch] = useState(false)
    const [groupBatchList,setGroupBatchList] = useState([])
    const [groupBatchDeletedList,setGroupBatchDeletedList] = useState([])
    const [transferGroupBatch,setTransferGroupBatch] = useState([])
    const [ageInDays,setAgeInDays] = useState(false)
    const resetBeforeEdit = () => {

        if (showDialog) {
            if (editMode) {
                setGroupBatchList(data?.groupBatches)
                updateData(data, editMode);
                if(transferBatch){
                    let movementData = formState.values.movementDate || new Date()
                    handleDefaultAge(data?.purchaseDate,movementData)
                }
            }else {
                setGroupBatchList([]) 
                setTransferBatch(false)
                updateData(data, editMode);
            }
        }
    };

    useEffect(resetBeforeEdit, [showDialog,data]);

    useEffect(()=>{
        if (transferBatch) {
            schema = tranferSchema
        }
    }, [transferBatch]);

    const onSubmit = () => {
        let breedIndex = formState.values.breedIndex
        let tempData = {
            ...formState.values,
            groupBatches:[...groupBatchList],
            deletedGroupbatches:[...groupBatchDeletedList],
            transferGroupBatches:[...transferGroupBatch],
            breedID:breeds[breedIndex]?.id,
        };
        if (editMode) {
            if(transferBatch) {
                //available Birds Validaiton
                let availableBirds = transferGroupBatch.reduce((total, batch) => total + batch.numberOfBirds, 0);

                if (availableBirds > data?.availableBirds) {
                    const excess = availableBirds - data.availableBirds;
                    setGroupErrorMessage(`The total number of birds in batches exceeds the available birds by ${excess}. Please decrease the number of birds.`);
                    return;
                } else if (availableBirds < data?.availableBirds) {
                    const shortage = data.availableBirds - availableBirds;
                    setGroupErrorMessage(`The total number of birds in batches is less than the available birds by ${shortage}. Please add more birds.`);
                    return;
                }
                onGroupBatchTransfer(tempData)
                if(transferBatch) {
                    setTransferBatch(false)
                    setTransferGroupBatch([])
                }
            } else {
                onEdit(tempData);
            }
        }else
            onSave(tempData);
    };

    const handleAgeInDays = ()=>{
        setAgeInDays(!ageInDays)
    }
    const handleMovementDateChange = (date)=>{
        handleChange({ target: { name: 'movementDate', value: date } })
        handleDefaultAge(formState.values.purchaseDate,date)
    }

    const handleDefaultAge = (purchaseData,movementData) => {
        if ( purchaseData && movementData) {
            let purchaseDate = new Date(purchaseData);
            let movementDate = new Date(movementData);
            const diffInMs = movementDate - purchaseDate;

            let defaultAge = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
            let ageInWeeks =  Math.round(defaultAge / 7)
            let ageInDays = defaultAge
            data.age = ageInWeeks
            data.ageInDays = ageInDays
            handleChange({ target: { name: 'age', value: ageInWeeks } })
            handleChange({ target: { name: 'ageInDays', value: ageInDays } })
        }
    };



    const handleTransferBatch = () => {
        let movementData = formState.values.movementDate || new Date()
        let breedIndex = formState.values.breedIndex
        let tempData = {
            ...formState.values,
            groupBatches:[...groupBatchList],
            deletedGroupbatches:[...groupBatchDeletedList],
            transferGroupBatches:[...transferGroupBatch],
            breedID:breeds[breedIndex]?.id,
            movementDate:movementData
        };
        onGroupBatchCost(tempData)
        handleDefaultAge(formState.values.purchaseDate,movementData)
        setTransferBatch(!transferBatch)
    }
    const createSubBatchName = (index,BatchList) => {
        let groupBatchName = formState.values.name; 
        let subBatchName;
    
        // Generate the subBatchName based on the current index
        let appendedChar = String.fromCharCode(65 + index); // 'A' starts at ASCII 65
        subBatchName = groupBatchName + " " + appendedChar; 
        // Check if the subBatchName already exists in the groupBatchList
        if (BatchList && BatchList.length > 0) {
            let tempGroupList = [...BatchList]
            while (checkIsDuplicate(subBatchName,tempGroupList)) {
                index++; // Increment index if the subBatchName exists
                appendedChar = String.fromCharCode(65 + index); // Update the appended character
                subBatchName = groupBatchName + " " + appendedChar; // Create new subBatchName
            }
        }
        return subBatchName;
    };
    const checkIsDuplicate = (name, batchlist) => {
        return batchlist?.findIndex((batch) => batch.name === name) !== -1
    }

    const onClose = () =>{
        if(schema.age !== undefined){
            delete schema.age
        }
        handleClose()
    }

    return <Dialog className={"GroupBatchContainer"} disableBackdropClick open={showDialog} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >{editMode ? 'Update' : 'New'} Group Batch
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
          {addBatchError ? <Chip onDelete={deleteErrorMessage} color="primary" label={addBatchError}/> : ''}
          
          </div>
            <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Group Batch Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
            />
             <FormControl className={classes.formControl}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Purchase Date"
                    format="dd/MM/yyyy"
                    value={formState.values.purchaseDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'purchaseDate', value: data } })
                    }}
                    animateYearScrolling
                    name="purchaseDate"
                />
            </MuiPickersUtilsProvider>

             </FormControl>
            {
                transferBatch ?
                <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                       <DatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Movement Date"
                        format="dd/MM/yyyy"
                        value={formState.values.movementDate || new Date()}
                        onChange={data => handleMovementDateChange(data)}
                        animateYearScrolling
                        name="movementDate"
                    />
                    </MuiPickersUtilsProvider> 
                </FormControl>
                : ""
            }
            <FormControl className={classes.formControl}>
                {
                    ageInDays ?

                    <TextField
                        error={hasError('ageInDays')}
                        fullWidth
                        helperText={
                            hasError('ageInDays') ? formState.errors.ageInDays[0] : null
                        }
                        label="Age (In Days)"
                        name="ageInDays"
                        onChange={(event) => {
                            const ageInWeeks = Math.floor(event.target.value / 7);  
                            handleChange({ target: { name: 'ageInDays', value:  parseFloat(event.target.value) } });
                            handleChange({ target: { name: 'age', value: ageInWeeks } });
                        }}
                         type="number"
                        value={formState.values.ageInDays || 0}
                        variant="outlined"
                    /> 
                    :
                    <TextField
                    error={hasError('age')}
                    fullWidth
                    helperText={
                        hasError('age') ? formState.errors.age[0] : null
                    }
                    label="Age (In Weeks)"
                    name="age"
                    onChange={event => {
                        const ageIndays = Math.floor(event.target.value * 7);  
                        handleChange({ target: { name: 'age', value: parseFloat(event.target.value) } })
                        handleChange({ target: { name: 'ageInDays', value: ageIndays } })
                    }}
                    type="number"
                    value={formState.values.age || 0}
                    variant="outlined"
                />
                }
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="breedType-select-label">Breed Type</InputLabel>
                <Select
                    id="breedType-select"
                    value={formState.values.breedIndex !== undefined ? formState.values.breedIndex : ''}
                    onChange={e => { 
                        handleChange({ target: { name: 'breedIndex', value: e.target.value } })
                    }}
                    name="breedIndex"
                    disabled={transferBatch}
                >
                    {breeds.length === 0 ?  
                        <MenuItem onClick={addBreedDialog}>
                          <AddIcon /> 
                          <div className={classes.addIconCss}>
                            Add
                          </div>
                        </MenuItem>
                    : breeds.map((breed, index) =>
                        <MenuItem key={breed.id} value={index}>{breed.name}</MenuItem>
                    )}
                </Select>
            </FormControl>

                   <div style={{marginTop:"5px",display:"inline-block"}}>
                     {
                      editMode ?
                        <div style={{marginTop:"5px",display:"inline-block"}}> 

                            <Switch
                                checked={transferBatch}
                                onChange={handleTransferBatch }
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /> Transfer Batch
                            </div>
                        :""}
                        <div style={{marginTop:"5px",display:"inline-block"}}> 

                        <Switch
                        checked={ageInDays}
                        onChange={handleAgeInDays}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        /> Age (in days)
                    </div>  
                </div>



            {
                transferBatch ?

                  <div  style={{ display:"flex",marginTop:"15px"}}>
                    <div style={{ display:"flex"}}>
                        <div style={{ fontSize: "medium", fontWeight: "700", margin: "10px 0px"}}>
                        Running Cost
                        </div>
                        <div style={{ marginLeft:"30px",marginTop:"3px",color:"white"}}>
                                <div style={{background: "#008000",padding:"3px 10px"}} 
                                                    className={classes.typeLabel}>
                                                                 {`₹ ${Math.abs(data.totalRunningCost)} ↓` }
                                </div>
                        </div>
                    </div>
                    <div style={{ display:"flex"}}>
                        <div style={{ fontSize: "medium", fontWeight: "700", margin: "10px 30px"}}>
                        Available Birds
                        </div>
                        <div style={{ marginLeft:"19px",marginTop:"3px",color:"white"}}>
                                <div style={{background: "#008000",padding:"3px 10px"}} 
                                                    className={classes.typeLabel}>
                                                                   {Math.abs(data?.availableBirds)}
                                                    </div>
                        </div>
                    </div>
                  </div>
                : ""
            }
           
            {
               formState.isValid ?
                <div>
                    {
                        transferBatch ?
                        <MaterialTable 
                        columns={[
                            {
                                title:"Shed Type", field: 'shedType',
                                editComponent: props => {
                                    return  <Select
                                                id="shedType-select"
                                                value={props.rowData.shedIndex}
                                                onChange={e => {
                                                    props.rowData.shedIndex = e.target.value
                                                    props.rowData.shedID = sheds[e.target.value].id
                                                    props.rowData.shedName =sheds[e.target.value].name
                                                    props.rowData.shedName =sheds[e.target.value].name
                                                }}
                                                name="shedIndex"
                                            >
                                         {sheds !== undefined && sheds !== null ?
                                            sheds.map((shed, index) => {
                                                // Check if the group batch already exists
                                                if (groupBatchList && groupBatchList.length > 0 && groupBatchList?.findIndex((item) => item.shedIndex === index) !== -1) {
                                                    return null; // Skip this item if found
                                                }
                                                // Return the MenuItem if the group batch doesn't exist
                                                return (
                                                    <MenuItem key={shed.id} value={index}>
                                                        {shed.name}
                                                    </MenuItem>
                                                );
                                            }) : ""
                                         }
                                             </Select>
                                },
                                render: rowData => {                        
                                    return <div>
                                     <div style={{textAlign:"center"}}>
                                             {
                                                 rowData.shedName !== "" && rowData.shedName !== undefined ? rowData.shedName  
                                                 : rowData.shedIndex !== undefined ?
                                                 sheds[rowData.shedIndex].name :""
                                             }
                                     </div>
                                             <div>
                                                     <div style={{background:rowData.name !== "" ? "#E8F7F4" :""}} 
                                                     className={classes.typeLabel}>
                                                                      {
                                                                         rowData.name !== undefined  && rowData.name !== ""?
                                                                         rowData.name :""
                                                                     }
                                                     </div>
                                                 </div>
                                    </div>
                                }
                            },
                          
                            {
                                title: "Number Of Birds",
                                field: "numberOfBirds",
                                type: "numeric",
                                editComponent: (props) => (
                                    <TextField
                                      type="number"
                                      value={props.value || ""}
                                      onChange={(e) => {                                
                                        const availableBirds = Math.abs(data?.availableBirds);
                                        const totalRunningCost = Math.abs(data.totalRunningCost) || 0;
                                        const inputValue = parseInt(e.target.value, 10) || 0;
                                        if(availableBirds > 0 && totalRunningCost > 0) {
                                            const costperbird =totalRunningCost / availableBirds
                                            const openingCost = inputValue * costperbird.toFixed(2)
                                            props.rowData.openingCost = Math.round(openingCost); 
                                            props.onChange(inputValue);
                                        }
                                      }}
                                    />
                                  )
                                
                            },
                            {
                                title:"Opening Cost", field: 'openingCost', type: 'numeric',
                                default: 0,initialEditValue: Math.abs(data.totalRunningCost),
                            }
                        ]}
                        data={transferGroupBatch}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,                    
                            pageSize:5,
                            toolbar: true
                        }}
                        editable={{
                            isEditable: (rowData) => rowData.isActive,  
                            isDeletable: (rowData) => rowData.isActive,  
                            onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                const rowIndex = transferGroupBatch?.length;
                                newData.name = createSubBatchName(rowIndex,[...transferGroupBatch,...groupBatchList]); 
                                newData.isActive = true
                                setTransferGroupBatch([...transferGroupBatch,newData])
                                resolve()
                            
                            }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    let updatedTransferGroupBatch = [...transferGroupBatch];
                                    const index = oldData.tableData.id;
                            
                            
                                    updatedTransferGroupBatch[index] = newData;
                                    setTransferGroupBatch(updatedTransferGroupBatch);
                                    resolve();
                                }),
                            onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
         
                                let transferGroupBatches = [...transferGroupBatch]
                                const index = oldData.tableData.id;
                                if (oldData.id) {
                                     let tempgroupBatchDeletedList = [...groupBatchDeletedList,transferGroupBatches[index]]
                                     setGroupBatchDeletedList([...tempgroupBatchDeletedList])
                                 }
                                 transferGroupBatches.splice(index, 1);
                                 setTransferGroupBatch([...transferGroupBatches])
                                 resolve()
                            }),
                        }}
                        style={{marginTop: '20px'}}
                         title='Group Batch Table'
                        /> :
                        <MaterialTable 
                        columns={[
                            {
                                title:"Shed Type", field: 'shedType',
                                editComponent: props => {
                                    return  <Select
                                                id="shedType-select"
                                                value={props.rowData.shedIndex}
                                                onChange={e => {
                                                    props.rowData.shedIndex = e.target.value
                                                    props.rowData.shedID = sheds[e.target.value].id
                                                    props.rowData.shedName =sheds[e.target.value].name
                                                    props.rowData.shedName =sheds[e.target.value].name
                                                }}
                                                name="shedIndex"
                                            >
                                         {sheds !== undefined && sheds !== null ?
                                            sheds.map((shed, index) => {
                                                // Check if the group batch already exists
                                                if (groupBatchList && groupBatchList.length > 0 && groupBatchList?.findIndex((item) => item.shedIndex === index) !== -1) {
                                                    return null; // Skip this item if found
                                                }
                                                // Return the MenuItem if the group batch doesn't exist
                                                return (
                                                    <MenuItem key={shed.id} value={index}>
                                                        {shed.name}
                                                    </MenuItem>
                                                );
                                            }) : ""
                                         }
                                             </Select>
                                },
                                render: rowData => {                        
                                    return <div>
                                     <div style={{textAlign:"center"}}>
                                             {
                                                 rowData.shedName !== "" && rowData.shedName !== undefined ? rowData.shedName  
                                                 : rowData.shedIndex !== undefined ?
                                                 sheds[rowData.shedIndex].name :""
                                             }
                                     </div>
                                             <div>
                                                     <div style={{background:rowData.name !== "" ? "#E8F7F4" :"",display:"flex",justifyContent:"space-around",alignItems:"center"}} 
                                                     className={classes.typeLabel}>
                                                                    <div>

                                                                            {
                                                                                rowData.name !== undefined  && rowData.name !== ""?
                                                                                rowData.name :""
                                                                            }
                                                                    </div>
                                                                      <div style={{background:rowData.isActive  ? "#008000" :"#f44336",color:"white",
                                                                        marginLeft:rowData.isActive  ? "0px" :"5px",height:"22px"
                                                                      }} 
                                                     className={classes.typeLabelActive}>
                                                                      {
                                                                         rowData.isActive ?
                                                                         "Active" :"Deactivated"
                                                                     }
                                                     </div>
                                                     </div>
                                                    
                                                 </div>
                                    </div>
                                }
                            },
                          
                            {
                                title:"Number Of Birds", field: 'numberOfBirds', type: 'numeric'
                            },
                            {
                                title:"Opening Cost", field: 'openingCost', type: 'numeric', default: 0,initialEditValue: 0,
                            }
                        ]}
                        data={ groupBatchList}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,                    
                            pageSize:5,
                            toolbar: true
                        }}
                        editable={{
                            isEditHidden: rowData =>  !rowData.isActive,
                            isDeleteHidden: rowData => !rowData.isActive,
                            onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                 if (newData) {
                                     const rowIndex = groupBatchList?.length;
                                     newData.name = createSubBatchName(rowIndex,[...transferGroupBatch,...groupBatchList]); 
                                     newData.isActive = true
                                    setGroupBatchList([...groupBatchList,newData])
                                    resolve()
                                 }
                            }),
                            onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                let groupBatches = [...groupBatchList]
                                const index = oldData.tableData.id;
                                groupBatches[index] = newData
                                setGroupBatchList([...groupBatches])
                                resolve()
                            }),
                            onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
         
                                let groupBatches = [...groupBatchList]
                                const index = oldData.tableData.id;
                                if (oldData.id) {
                                     let tempgroupBatchDeletedList = [...groupBatchDeletedList,groupBatches[index]]
                                     setGroupBatchDeletedList([...tempgroupBatchDeletedList])
                                 }
                                groupBatches.splice(index, 1);
                                setGroupBatchList([...groupBatches])
                                resolve()
                            }),
                        }}
                        style={{marginTop: '20px'}}
                         title='Group Batch Table'
                        />

                    }
                </div>
              
               : ""
            }
             <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || groupBatchList.length === 0}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
        <Breed 
        deleteErrorMessage={deleteErrorMessage} 
        handleClose={closeBreedDialog}
        showDialog={showBreedDialog} 
        onSave={onBreedSave} 
        />
    </Dialog>

};

export default GroupBatch;